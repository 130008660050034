import { useEffect, useRef } from 'react'
import { Drawer, DrawerContent, DrawerHeader, DrawerTitle } from '@/components/ui/drawer'

import { Button } from '@/components/ui/button'
import { useMutation } from '@tanstack/react-query'
import { type Chat } from '@/store/types'
import axios from 'axios'
import { SERVER_URL } from '@/env-vars'
import { Store } from '@/store'
import { useChatIdFromUrl } from '@/hooks/useChatIdFromUrl'
import { useAuthFromStorage } from '@/hooks/useAuthStorage'
import { Accordion } from './ui/accordion'
import ChatCategoryDropdown from './ChatCategoryDropdown'
import { useGuestID } from '@/hooks/useGuestID'
import { useNavigate } from 'react-router-dom'
import { RectangleHorizontal } from 'lucide-react'

const Sidebar = () => {
	const navigate = useNavigate()
	const isSidebarOpen = Store.useBoundStore(state => state.isSidebarOpen)
	const chats = Store.useBoundStore(state => state.chats)
	const { authToken, setAuthToken } = useAuthFromStorage()
	const { setChatId } = useChatIdFromUrl()
	const { resetGuestID } = useGuestID()

	const drawerRef = useRef<HTMLDivElement>(null)

	const token = () => `Bearer ${authToken}`

	const getAllChats = useMutation<Chat[]>({
		mutationKey: ['chats'],
		mutationFn: async () => {
			if (!authToken) return []
			return axios.get(`${SERVER_URL}/chat/all`, {
				headers: { Authorization: token() },
				params: { limit: 200, offset: 0 },
			}).then((res) => res.data.chats)
		},
		onSuccess: (newChats) => {
			Store.updateChats({ newChats, updateMessages: false })
		},
	})

	const deleteAllChats = useMutation({
		mutationKey: ['delete-all-chats'],
		mutationFn: async () => {
			await axios.delete(`${SERVER_URL}/chat/id`, {
				headers: { Authorization: token() },
			})
		},
		onSuccess: () => {
			Store.setState(draft => {
				draft.chatId = undefined
				draft.chats = {}
				draft.isSidebarOpen = false
			})
		},
	})

	useEffect(() => {
		getAllChats.mutate()
	}, [authToken])

	// sort chats by lastUpdatedAt
	const sortedChats = Object.values(chats).sort((a, b) => {
		const aDate = new Date(a.lastUpdatedAt).getTime()
		const bDate = new Date(b.lastUpdatedAt).getTime()
		return bDate - aDate
	})

	// group the chats by categories
	const groupedChats = sortedChats.reduce((acc, chat) => {
		// if(!chat.category) return acc;
		const category = chat.category ?? 'Others'
		if (acc[category] === undefined) {
			acc[category] = []
		}
		acc[category].push(chat)
		return acc
	}, {} as Record<string, Chat[]>)

	// rank chats by frequency of chats
	const rankedChats = Object.entries(groupedChats).sort(([_a, aChats], [_b, bChats]) => {
		// if any of the chat group is "Others", it should be at the end
		if (_a === 'Others') return 1
		if (_b === 'Others') return -1
		return bChats.length - aChats.length
	})

	const handleLogout = () => {
		Store.setState(draft => {
			draft.alertDialog = {
				title: 'Are you sure?',
				description: 'This will delete your account and all your chats permanently',
				onConfirm: () => deleteAllChats.mutate(),
			}
		})
	}

	const handleSavedVideos = () => {
		Store.setState(draft => {
			draft.isSidebarOpen = false
		})
		navigate('/saved')
	}

	return (
		<Drawer
			open={isSidebarOpen}
			onOpenChange={(open) =>
				Store.setState(draft => {
					draft.isSidebarOpen = open
				})}
			direction='left'
		>
			<DrawerContent
				ref={drawerRef}
				/**
				 * For some reason, double border (red and white appears outside the drawer border when it is focus for the first time
				 * so the hacky fix i found was to blur it whenever it gets focused.
				 */
				onFocus={() => drawerRef.current?.blur()}
				className='bg-primary text-primary-foreground h-dvh sm:w-80 w-64 rounded-none border-none'
			>
				<DrawerHeader>
					<DrawerTitle
						className='text-2xl'
						onClick={() => console.debug('chats:\n', Object.values(chats))}
					>
						Recent Chats
					</DrawerTitle>
				</DrawerHeader>
				<div className='mx-4 h-full flex flex-col'>
					<div className='flex-grow'>
						<div className='max-h-[60vh] overflow-y-scroll border-b border-zinc-500'>
							<Accordion type='single' collapsible>
								{rankedChats.map(([category, chats]) => (
									<ChatCategoryDropdown
										key={category}
										category={category}
										chats={chats}
										onChatSelect={(chatId) => {
											setChatId(chatId)
											Store.setState(draft => {
												draft.isSidebarOpen = false
											})
										}}
									/>
								))}
							</Accordion>
						</div>
					</div>
					<div className='flex justify-left mb-4'>
						<Button onClick={handleSavedVideos}>
							<span>Saved Videos</span>
							<p className='ml-2 px-1.5 py-0.5 text-xs text-black rounded-sm border-spacing-2 bg-gradient-to-r from-[#1CF0FA] to-[#ADFF69]'>
								New
							</p>
						</Button>
					</div>
					{authToken && (
						<div className='flex flex-col gap-4 p-4'>
							<div>
								<Button variant='destructive' onClick={handleLogout}>Delete History</Button>
							</div>
						</div>
					)}
					<div className='mb-8'>
						See <a href='/privacy-policy' className='underline'>Privacy Policy</a> and{' '}
						<a href='/terms-of-service' className='underline'>Terms of Service</a>
					</div>
				</div>
			</DrawerContent>
		</Drawer>
	)
}

export default Sidebar
