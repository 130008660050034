import { useMutation } from '@tanstack/react-query'
import { toast } from 'sonner'
import * as Sentry from '@sentry/react'

export function useFileUpload() {
	return useMutation({
		mutationKey: ['storage-file-upload'],
		mutationFn: async ({ url, file }: { url: string; file: File }) => {
			const response = await fetch(url, {
				method: 'PUT',
				body: file,
			})
			if (response.status >= 400) {
				throw Error(`Error Uploading File: ${response.status}, ${response.text()}`)
			}
		},
		onError: (error, { file }) => {
			toast('There was an issue uploading the File.', {
				description: 'Please try again',
				dismissible: true,
				action: {
					label: 'Ok',
					onClick: () => {},
				},
				duration: 5 * 1000,
			})
			Sentry.captureException(error, {
				tags: {
					type: 'api-call',
					id: 'file-upload',
					fileSize: file.size,
					fileType: file.type,
				},
			})
		},
	})
}
