import React from 'react'
import { Card, CardContent } from '@/components/ui/card'
import { YoutubeVideoDetails } from '@/store/types'
import { Store } from '@/store'

const VideoRecommendationCard = (video: YoutubeVideoDetails) => (
	<Card
		className='w-[152px] flex-shrink-0 inline-block border-none rounded-sm bg-tranparent'
		onClick={() =>
			Store.setState(draft => {
				if (!draft.videoRecommendations) return
				draft.videoRecommendations.currentVideo = video
			})}
	>
		<CardContent className='p-2 bg-[#2C2C2C] text-white rounded-2xl'>
			{/* <img src={video.thumbnailLink} className='w-full h-28 rounded-md mb-2' /> */}
			<div className='relative w-[136px] h-[78px] flex-shrink-0'>
				<img
					src={video.thumbnailLink}
					alt='thumbnail'
					className='rounded-lg object-cover w-full h-full'
				/>
				{video.duration && (
					<div className='text-sm text-white absolute bottom-2 right-2 bg-black p-0.5 rounded-lg bg-opacity-50'>
						{video.duration}
					</div>
				)}
			</div>
			<h3 className='text-sm font-semibold line-clamp-2'>{video.title}</h3>
			<p className='text-xs text-gray-300'>{video.duration}</p>
		</CardContent>
	</Card>
)

export default VideoRecommendationCard
