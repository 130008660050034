import { type TextMessage } from '@/store/types'
import AssistantChatMessage from '@/components/AssistantMessage'
import MarkdownRenderer from '@/components/MarkdownRenderer'
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion'
import { useChatIdFromUrl } from '@/hooks/useChatIdFromUrl'
import { Check, ChevronDown, Copy } from 'lucide-react'
import { useClipboard } from '@mantine/hooks'
import { Button } from '@/components/ui/button'

type Step = { heading: string; content: string }

const splitMarkdownHeadings = (markdown: string) => {
	// Split the markdown content by lines
	const lines = markdown.split('\n')

	const result = [] as Step[]
	let currentHeading: string | null = null
	let currentContent = [] as string[]

	lines.forEach(line => {
		const headingMatch = line.match(/^###\s+(.+)/) // Match level 3 headings (###)

		if (headingMatch) {
			// If we encounter a heading and there's content from the previous section, save it
			if (currentHeading && currentContent.join().trim().length) {
				result.push({
					heading: currentHeading,
					content: currentContent.join('\n').trim(),
				})
			}

			// Start a new section with the new heading
			currentHeading = headingMatch[1]
			currentContent = [] // Reset content for this heading
		} else if (currentHeading) {
			// If we're inside a section (after a heading), accumulate the content
			currentContent.push(line)
		}
	})

	// After the loop, make sure to push the last section if it exists
	if (currentHeading) {
		result.push({
			heading: currentHeading,
			content: currentContent.join('\n').trim(),
		})
	}

	return result
}

function CopyIcon({ content }: { content: string }) {
	const clipboard = useClipboard({ timeout: 1500 })
	return (
		<>
			{clipboard.copied ? <Check size={16} className='m-2 mb-0 opacity-80' /> : (
				<Copy
					size={16}
					className='m-2 mb-0 opacity-80 cursor-pointer'
					onClick={() => clipboard.copy(content)}
				/>
			)}
		</>
	)
}

function AccordionSteps({ steps }: { steps: Step[] }) {
	const defaultOpened = Array.from({ length: 100 }, (_, i) => i.toString())

	const { chatId } = useChatIdFromUrl()
	return (
		<div className='w-full max-w-full bg-[#212121] px-3 py-4 flex flex-col rounded-2xl'>
			<h2 className='font-semibold mb-4'>
				Steps
			</h2>
			<Accordion
				type='multiple'
				className='w-full max-w-full'
				// All of the Steps should be opened by default
				defaultValue={defaultOpened}
				// React uses it to identify unique Accodions based on ChatId instead of mutating the one
				key={chatId}
			>
				{steps.map((step, idx) => (
					<AccordionItem
						value={idx.toString()}
						key={idx}
						className='mb-2'
					>
						<AccordionTrigger className='text-left rounded-t-sm px-4 bg-[#2C2C2C]'>
							<div className='bg-gradient-to-r from-[#7EFBE3] to-[#70FDB0] min-w-5 min-h-5 rounded-full text-black text-xs font-semibold grid place-items-center mr-2'>
								{idx + 1}
							</div>
							<div className='flex-grow'>
								<MarkdownRenderer
									className='inline-block w-full text-primary-foreground text-sm'
									content={step.heading}
								/>
							</div>
							<ChevronDown className='h-5 w-5 shrink-0 transition-transform duration-200 text-[#7EFBE3]' />
						</AccordionTrigger>
						<AccordionContent>
							<div className='w-full max-w-full border-t pt-2 border-zinc-700  rounded-b-sm px-4 pb-2 bg-[#2C2C2C]'>
								<div className='overflow-x-scroll w-full flex'>
									<MarkdownRenderer
										className='inline-block text-primary-foreground w-full'
										content={step.content}
									/>
								</div>
							</div>
							<div className='flex'>
								<CopyIcon content={step.heading + '\n\n' + step.content} />
							</div>
						</AccordionContent>
					</AccordionItem>
				))}
			</Accordion>
		</div>
	)
}

export default function TextMessage({ message }: { message: TextMessage }) {
	const steps = splitMarkdownHeadings(message.content)
	const clipboard = useClipboard({ timeout: 1500 })
	const clipboardAll = useClipboard({ timeout: 1500 })

	if (message.role === 'user') {
		return (
			<div className='mb-4 ml-8 md:ml-32 text-white text-right'>
				<div className='inline-block rounded-lg min-w-64 text-left'>
					<div className='p-2 bg-gradient-to-r from-[#7EFBE3] to-[#70FDB0] text-primary rounded-l-xl rounded-ee-xl py-4 px-4 font-medium text-sm'>
						{message.content}
					</div>
				</div>
			</div>
		)
	}

	return (
		<div className='mb-4 text-white text-left'>
			<div className='inline-block rounded-lg min-w-64 max-w-[80vw] text-left text-primary-foreground'>
				{message.content !== '' && (
					<AssistantChatMessage>
						{steps.length
							? (
								<div>
									<AccordionSteps steps={steps} />
									<div className='flex'>
										<Button onClick={() => clipboardAll.copy(message.content)}>
											Copy All
											{clipboardAll.copied
												? <Check size={16} className='m-2 opacity-80' />
												: (
													<Copy
														size={16}
														className='m-2 opacity-80 cursor-pointer'
													/>
												)}
										</Button>
									</div>
								</div>
							)
							: (
								<div>
									<div className='bg-[#212121] rounded-2xl px-2 py-2'>
										<MarkdownRenderer
											className='inline-block text-primary-foreground text-justify w-full'
											content={message.content}
										/>
									</div>
									<div className='flex'>
										{clipboard.copied ? <Check size={16} className='m-2 opacity-80' /> : (
											<Copy
												size={16}
												className='m-2 opacity-80 cursor-pointer'
												onClick={() => clipboard.copy(message.content)}
											/>
										)}
									</div>
								</div>
							)}
					</AssistantChatMessage>
				)}
			</div>
		</div>
	)
}
