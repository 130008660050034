import { useMutation } from '@tanstack/react-query'
import axios from 'axios'
import { SERVER_URL } from '@/env-vars'
import { useAuthFromStorage } from '@/hooks/useAuthStorage'
import { toast } from 'sonner'
import * as Sentry from '@sentry/react'

export function useSignedUrlMutation() {
	const { authToken } = useAuthFromStorage()

	return useMutation({
		mutationKey: ['signed-url'],
		mutationFn: async ({ extension }: { extension: string }) => {
			const response = await axios.get(`${SERVER_URL}/storage/presigned-urls`, {
				params: { extension },
				headers: { Authorization: `Bearer ${authToken}` },
			})
			return response.data as {
				fileId: string
				uploadUrl: string
				downloadUrl: string
				publicUrl: string
			}
		},
		onError: (error, { extension }) => {
			toast('Server’s taking a nap.', {
				description: 'Try again soon!',
				dismissible: true,
				action: {
					label: 'Ok',
					onClick: () => {},
				},
				duration: 5 * 1000,
			})
			Sentry.captureException(error, {
				tags: {
					type: 'api-call',
					id: 'signed-url',
					extension: extension,
				},
			})
		},
	})
}
