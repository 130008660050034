import { Chat } from '@/store/types'
import { AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion'
import { ScrollArea } from '@/components/ui/scroll-area'
import { Separator } from '@/components/ui/separator'
import { ChevronDown } from 'lucide-react'

type ChatCategoryDropdownProps = {
	category: string
	chats: Chat[]
	onChatSelect: (chatId: string) => void
}

export default function ChatCategoryDropdown({ category, chats, onChatSelect }: ChatCategoryDropdownProps) {
	return (
		<AccordionItem value={category} className='border-none'>
			<AccordionTrigger>
				<div className='inline-block flex-grow text-left'>{category}</div>
				<ChevronDown className='h-4 w-4 shrink-0 transition-transform duration-200' />
			</AccordionTrigger>
			<AccordionContent>
				<div className='max-h-48 overflow-y-scroll'>
					{chats.map((chat, index) => (
						<div key={index} className='text-white'>
							<div className='flex'>
								<div className='cursor-pointer' onClick={() => onChatSelect(chat.chatId)}>
									<span>{chat.chatTitle ?? 'Untitled Chat'}</span>
								</div>
							</div>
							<Separator className='my-1 bg-zinc-700' />
						</div>
					))}
				</div>
			</AccordionContent>
		</AccordionItem>
	)
}
