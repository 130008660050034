import VideoRecommendationSection from '@/components/VideoRecommendationSection'
import ReactPlayer from 'react-player/youtube'
import { Store } from '@/store'
import { useElementSize } from '@mantine/hooks'
import { useNavigate } from 'react-router-dom'
import { ArrowLeft, Bookmark, CircleCheck } from 'lucide-react'
import { Button } from './ui/button'
import { useEffect, useState } from 'react'
import { SaveVideoType } from '@/store/types'
import { useChatIdFromUrl } from '@/hooks/useChatIdFromUrl'
import { useSaveVideo } from '@/mutations/useSaveVideo'
import { useRemoveVideo } from '@/mutations/useRemoveVideo'
import { useListVideos } from '@/hooks/useListVideos'
import { useQueryClient } from '@tanstack/react-query'

export default function YouTubeVideoScreen() {
	const navigate = useNavigate()
	const queryClient = useQueryClient()
	const [isSaved, setIsSaved] = useState(false)
	const [showSavedMessage, setShowSavedMessage] = useState(false)
	const { chatId } = useChatIdFromUrl()
	const videoRecommendations = Store.useBoundStore(store => store.videoRecommendations)
	const { ref, height } = useElementSize()
	const addToSavedVideos = useSaveVideo()
	const removeFromSavedVideos = useRemoveVideo()
	const { data: savedVideos } = useListVideos()

	if (!videoRecommendations) {
		return (
			<div>
				No Videos Selected in State
			</div>
		)
	}
	const { short, medium, long } = videoRecommendations?.currentMessage.content

	// Fetch saved videos on mount
	useEffect(() => {
		if (savedVideos && videoRecommendations?.currentVideo) {
			const isVideoAlreadySaved = savedVideos.some(
				(video: any) => {
					return video.title === videoRecommendations.currentVideo.title
				},
			)
			setIsSaved(isVideoAlreadySaved)
		}
	}, [savedVideos, videoRecommendations?.currentVideo])

	const handleSaveVideo = () => {
		const videoData = {
			chatId: chatId,
			video: {
				title: videoRecommendations.currentVideo.title,
				videoLink: videoRecommendations.currentVideo.videoLink,
				thumbnailLink: videoRecommendations.currentVideo.thumbnailLink,
				duration: videoRecommendations.currentVideo.duration,
			},
			recommendations: {
				short: videoRecommendations.currentMessage.content.short,
				medium: videoRecommendations.currentMessage.content.medium,
				long: videoRecommendations.currentMessage.content.long,
			},
		} as SaveVideoType

		if (!isSaved) {
			setIsSaved(true)
			setShowSavedMessage(true)
			setTimeout(() => setShowSavedMessage(false), 3000)
			addToSavedVideos.mutate(videoData, {
				onSuccess: () => {
					queryClient.invalidateQueries({ queryKey: ['list-saved-videos'] })
				},
			})
		} else {
			setIsSaved(false)
			removeFromSavedVideos.mutate(videoData.video.title, {
				onSuccess: () => {
					queryClient.invalidateQueries({ queryKey: ['list-saved-videos'] })
				},
			})
		}
	}

	return (
		<div className='grid place-items-center'>
			<div className='max-w-2xl px-2 flex-1 h-svh table table-fixed w-full sm:w-[624px]'>
				<div className='flex justify-between items-center my-3'>
					<div className='w-20'>
						<div
							onClick={() => navigate(-1)}
							className='flex justify-between items-center text-left px-2 rounded-3xl text-primary-foreground cursor-pointer'
						>
							<ArrowLeft size={24} />
						</div>
					</div>

					<div className='w-48 flex justify-end'>
						<Button
							variant='ghost'
							size='icon'
							className='bg-opacity-10 hover:bg-green-700 hover:bg-opacity-20'
							onClick={handleSaveVideo}
						>
							{isSaved ? <img src='/bookmark-filled.svg' /> : <img src='/bookmark-unfilled.svg' />}
						</Button>
					</div>
				</div>

				<div className='table-row relative'>
					<div className='w-full aspect-video bg-gray-900 mb-4 relative'>
						<ReactPlayer
							url={videoRecommendations.currentVideo.videoLink}
							controls
							width='100%'
							height='100%'
							playing={true}
						/>
						{showSavedMessage && (
							<div className='absolute inset-0 flex items-center justify-center'>
								<div className='bg-white text-black font-bold px-4 py-2 rounded-md text-sm flex items-center'>
									<CircleCheck className='mr-2 text-white fill-green-400' />
									Video Saved
								</div>
							</div>
						)}
					</div>
				</div>

				<div ref={ref} className='space-y-6 h-[100%] table-row'>
					<div
						className='overflow-y-scroll w-full sm:w-[624px] absolute'
						style={{ height }}
					>
						{short.length !== 0 && (
							<VideoRecommendationSection
								title='Quick Learnings'
								videos={short}
							/>
						)}
						{medium.length !== 0 && (
							<VideoRecommendationSection
								title='Brief Lectures'
								videos={medium}
							/>
						)}
						{long.length !== 0 && (
							<VideoRecommendationSection
								title='Long Lectures'
								videos={long}
							/>
						)}
					</div>
				</div>
			</div>
		</div>
	)
}
