import { type ImageMessage } from '@/store/types'
import UserMessage from '@/components/UserMessage'

export default function ImageMessage({ message }: { message: ImageMessage }) {
	return (
		<UserMessage>
			<div className='flex justify-end'>
				<img src={message.content} alt='image' className='rounded-lg h-72' />
			</div>
		</UserMessage>
	)
}
