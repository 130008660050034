import {
	AlertDialog,
	AlertDialogAction,
	AlertDialogCancel,
	AlertDialogContent,
	AlertDialogDescription,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogTitle,
} from '@/components/ui/alert-dialog'
import { Store } from '@/store'

export default function GlobalAlertDialog() {
	const dialogState = Store.useBoundStore((state) => state.alertDialog)

	const handleOpenChange = (open: boolean) => {
		Store.setState((draft) => {
			if (!open) {
				draft.alertDialog = undefined
			}
		})
	}

	return (
		<AlertDialog open={Boolean(dialogState)} onOpenChange={handleOpenChange}>
			<AlertDialogContent className='bg-primary border-secondary w-5/6'>
				<AlertDialogHeader>
					<AlertDialogTitle className='text-primary-foreground'>
						{dialogState?.title || `Are you absolutely sure?`}
					</AlertDialogTitle>
					<AlertDialogDescription>
						{dialogState?.description || `This action cannot be undone.`}
					</AlertDialogDescription>
				</AlertDialogHeader>
				<AlertDialogFooter>
					<AlertDialogCancel>Cancel</AlertDialogCancel>
					<AlertDialogAction className='bg-red-600 hover:bg-red-600' onClick={dialogState?.onConfirm}>
						Continue
					</AlertDialogAction>
				</AlertDialogFooter>
			</AlertDialogContent>
		</AlertDialog>
	)
}
