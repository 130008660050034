import { EllipsisVerticalIcon, Trash2Icon } from 'lucide-react'
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuLabel,
	DropdownMenuSeparator,
	DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { Store } from '@/store'
import { useMutation } from '@tanstack/react-query'
import axios from 'axios'
import { SERVER_URL } from '@/env-vars'
import { useAuthFromStorage } from '@/hooks/useAuthStorage'

export default function ChatMenu() {
	const chatId = Store.useBoundStore((state) => state.chatId)

	const { authToken } = useAuthFromStorage()

	const token = () => `Bearer ${authToken}`

	const deleteChat = useMutation({
		mutationKey: ['delete-chat', chatId],
		mutationFn: async () => {
			if (!chatId) throw Error('ChatId Not Found')
			await axios.delete(`${SERVER_URL}/chat/id/${chatId}`, {
				headers: { Authorization: token() },
			})
			return chatId
		},
		onSuccess: (chatId) => {
			Store.setState((draft) => {
				delete draft.chats[chatId]
				draft.chatId = undefined
			})
		},
	})

	const handleDeleteChat = () => {
		if (!chatId) return
		Store.setState((draft) => {
			draft.alertDialog = {
				title: 'Are you sure?',
				description:
					'This action cannot be undone. This will permanently delete your chat and remove your data from our servers.',
				onConfirm: () => deleteChat.mutate(),
			}
		})
	}

	return (
		<DropdownMenu>
			<DropdownMenuTrigger asChild>
				<EllipsisVerticalIcon className='cursor-pointer text-[#929093]' />
			</DropdownMenuTrigger>
			<DropdownMenuContent className='w-56 bg-secondary text-primary-foreground border-zinc-700 p-2 m-2'>
				<DropdownMenuLabel>Chat Options</DropdownMenuLabel>
				<DropdownMenuSeparator className='bg-zinc-600' />
				<DropdownMenuItem disabled={!chatId} className='cursor-pointer' onClick={handleDeleteChat}>
					<Trash2Icon className='mr-2 h-4 w-4' />
					<span>Delete</span>
				</DropdownMenuItem>
			</DropdownMenuContent>
		</DropdownMenu>
	)
}
