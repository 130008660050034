import { type VideoSuggestionMessage } from '@/store/types'
import AssistantMessage from '@/components/AssistantMessage'

/**
 * **`Deprecated`** in favor of Video Recommendations
 */
export default function VideoSuggestionMessage({ message }: { message: VideoSuggestionMessage }) {
	return (
		<AssistantMessage>
			<div className='flex flex-col gap-2'>
				<h2 className='mb-2 text-xl'>Here is Some Related Video Content You May Find Interesting</h2>
				<div className='grid grid-cols-1 sm:grid-cols-2 gap-2 max-w-[32rem]'>
					{message.content.map((video, idx) => (
						<a key={idx} href={video.videoLink} target='_blank'>
							<div className='bg-zinc-700 p-2 rounded-lg max-w-64 cursor-pointer'>
								<div className='relative'>
									<img src={video.thumbnailLink} alt='thumbnail' />
									{video.duration && (
										<div className='text-sm text-white absolute bottom-2 right-2 bg-black p-0.5 rounded-sm bg-opacity-50'>
											{video.duration}
										</div>
									)}
								</div>
								<div className='text-white'>{video.title}</div>
							</div>
						</a>
					))}
				</div>
			</div>
		</AssistantMessage>
	)
}
