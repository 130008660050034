import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.tsx'
import './index.css'
import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import * as Sentry from '@sentry/react'

Sentry.init({
	dsn: 'https://d3a008e5ad9c08b5b5b1972ec341e3ef@o4508161571028992.ingest.us.sentry.io/4508161574043648',
	integrations: [
		Sentry.browserTracingIntegration(),
		Sentry.replayIntegration({
			// This will disable built-in masking.
			// https://docs.sentry.io/platforms/javascript/session-replay/privacy/#privacy-configuration
			maskAllText: false,
			blockAllMedia: false,
		}),
		Sentry.replayCanvasIntegration(),
	],
	// Tracing
	tracesSampleRate: 1.0, //  Capture 100% of the transactions
	// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
	tracePropagationTargets: [
		'localhost',
		/https:\/\/solvemate-ai\.jetrr\.app\/.*/,
		/https:\/\/solvemate-development\.jetrr\.app\/.*/,
		/https:\/\/production\.solvemate\.jetrr\.app\/.*/,
		/https:\/\/development\.solvemate\.jetrr\.app\/.*/,
	],
	// Session Replay
	replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

// set this to true temporarily to test initializing for local dev
const ALLOW_FIREBASE_FOR_DEV = false

// Initialize firebase only for production URLs
if (
	ALLOW_FIREBASE_FOR_DEV ||
	window.location.hostname.includes('solvemate-ai.jetrr.app') ||
	window.location.hostname.includes('production.solvemate.jetrr.app')
) {
	// non sensitive keys
	const firebaseConfig = {
		apiKey: 'AIzaSyA2M7nimBhCHyqxErFb5jJWfa94Z0U1lww',
		authDomain: 'jetrr-vision.firebaseapp.com',
		projectId: 'jetrr-vision',
		storageBucket: 'jetrr-vision.appspot.com',
		messagingSenderId: '618181588714',
		appId: '1:618181588714:web:7bcfead8acec6017a48993',
		measurementId: 'G-M2YTDTH4KN',
	}

	// Initialize Firebase
	const app = initializeApp(firebaseConfig, { name: 'solvemate-prod-web', automaticDataCollectionEnabled: true })
	const analytics = getAnalytics(app)
}

ReactDOM.createRoot(document.getElementById('root')!).render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
)
