/**
 * Snippet Taken From Sandbox Example:
 * https://codesandbox.io/p/sandbox/react-image-crop-demo-with-react-hooks-y831o?file=%2Fsrc%2FuseDebounceEffect.ts%3A1%2C1-18%2C1
 */

import React, { useRef, useState } from 'react'
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from '@/components/ui/dialog'
import ReactCrop, { centerCrop, type Crop, makeAspectCrop, PercentCrop, type PixelCrop } from 'react-image-crop'
import { useDebounceEffect } from 'ahooks'
import 'react-image-crop/dist/ReactCrop.css'
import { canvasPreview } from '@/components/canvasPreview'
import { Button } from '@/components/ui/button'
import { RotateCwIcon } from 'lucide-react'
import Spinner from '@/components/Spinner'
import { Store } from '@/store'

/**
 * Downsizes the image dimensions according to how OpenAI does?
 * if we can do this on client side, this means that the same downsizing
 * that was supposed to happen on OPENAI servers happens at the Client Side.
 * - This would help with storage and bandwidth costs as we scale
 * - client side downsizing mean faster image upload time for user for larger images
 * https://platform.openai.com/docs/guides/vision/calculating-costs
 */
const downscaleDimensions = ({ width, height }: { width: number; height: number }) => {
	const MAX_DIMENSION = 2048
	const TARGET_SMALLEST_SIDE = 768

	// Step 1: Downscale to fit within the 2048 x 2048 square
	if (width > MAX_DIMENSION || height > MAX_DIMENSION) {
		if (width > height) {
			// If width is the largest side
			const scaleFactor = MAX_DIMENSION / width
			width = MAX_DIMENSION
			height = Math.floor(height * scaleFactor)
		} else {
			// If height is the largest side
			const scaleFactor = MAX_DIMENSION / height
			height = MAX_DIMENSION
			width = Math.floor(width * scaleFactor)
		}
	}

	// Step 2: Downscale the smallest side to 768
	if (width < height && width > TARGET_SMALLEST_SIDE) {
		const scaleFactor = TARGET_SMALLEST_SIDE / width
		width = TARGET_SMALLEST_SIDE
		height = Math.floor(height * scaleFactor)
	} else if (height < width && height > TARGET_SMALLEST_SIDE) {
		const scaleFactor = TARGET_SMALLEST_SIDE / height
		height = TARGET_SMALLEST_SIDE
		width = Math.floor(width * scaleFactor)
	}

	return { width, height }
}

type ImageCropperViewProps = {
	handleCropDone: (croppedImage: File) => Promise<void>
}

export default function ImageCropperView({
	handleCropDone,
}: ImageCropperViewProps) {
	const cropImage = Store.useBoundStore((state) => state.imageCropView)
	const previewCanvasRef = useRef<HTMLCanvasElement>(null)
	const imgRef = useRef<HTMLImageElement>(null)
	const [crop, setCrop] = useState<Crop>()
	const [completedCrop, setCompletedCrop] = useState<PixelCrop>()
	const [scale, setScale] = useState(1)
	const [rotate, setRotate] = useState(0)
	const [loading, setLoading] = useState(false)
	const [imgDim, setImgDim] = useState({ height: 0, width: 0 })

	function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
		setCrop({ x: 0, y: 0, unit: '%', height: 100, width: 100 })
		const { width, height } = e.currentTarget
		setImgDim({ height: height, width: width })
	}

	async function onCropDoneClick() {
		const image = imgRef.current
		const previewCanvas = previewCanvasRef.current
		if (!image || !previewCanvas || !completedCrop) {
			throw new Error('Crop canvas does not exist')
		}
		const scaleX = image.naturalWidth / image.width
		const scaleY = image.naturalHeight / image.height
		const { width, height } = downscaleDimensions({
			height: completedCrop.height * scaleY,
			width: completedCrop.width * scaleX,
		})
		// check if width is somehow less than zero to prevent IndexRangeError with negative indices
		const offscreen = new OffscreenCanvas(
			width,
			height,
		)
		const ctx = offscreen.getContext('2d')
		if (!ctx) {
			throw new Error('No 2d context')
		}

		ctx.drawImage(
			previewCanvas,
			0,
			0,
			previewCanvas.width,
			previewCanvas.height,
			0,
			0,
			offscreen.width,
			offscreen.height,
		)
		// You might want { type: "image/jpeg", quality: <0 to 1> } to
		// reduce image size
		const blob = await offscreen.convertToBlob({
			type: 'image/png',
		})
		const file = new File([blob], 'image.png', {
			type: 'image/png',
		})

		// upload it to s3 like storage url
		setLoading(true)
		await handleCropDone(file)
			.catch(console.error)
			.finally(() => setLoading(false))

		Store.setState((draft) => {
			draft.imageCropView = {
				isDialogOpen: false,
			}
		})
	}

	function onRotateClockwise() {
		// Adjust Scale According to rotation
		if (rotate % 180 == 0) {
			// Orientation is about to change
			const maxDim = Math.max(imgDim.height, imgDim.width)
			const minDim = Math.min(imgDim.height, imgDim.width)
			setScale(minDim / maxDim)
		} else {
			// About to Revert to initial Orientation
			setScale(1)
		}
		setRotate((r) => (r + 90) % 360)
	}

	useDebounceEffect(
		() => {
			if (
				completedCrop?.width &&
				completedCrop?.height &&
				imgRef.current &&
				previewCanvasRef.current
			) {
				// We use canvasPreview as it's much faster than imgPreview.
				canvasPreview(
					imgRef.current,
					previewCanvasRef.current,
					completedCrop,
					scale,
					rotate,
				)
			}
		},
		// Depend on these values changing
		[completedCrop, scale, rotate, cropImage],
		// Run every 100ms
		{ wait: 100 },
	)

	const handleCropChange = (_crop: PixelCrop, percentageCrop: PercentCrop) => {
		if (percentageCrop.height && percentageCrop.width) {
			setCrop(percentageCrop)
		}
	}

	return (
		<Dialog open={Boolean(cropImage.isDialogOpen)}>
			<DialogContent className='bg-primary text-primary-foreground border-none'>
				<DialogHeader>
					<DialogTitle>Scan Question</DialogTitle>
					<DialogDescription>Crop only one question</DialogDescription>
				</DialogHeader>
				<div className='flex justify-center'>
					{!!cropImage.imageDataUrl && (
						<ReactCrop
							crop={crop}
							onChange={handleCropChange}
							onComplete={(c) => setCompletedCrop(c)}
							minWidth={120}
							minHeight={40}
							keepSelection={true}
						>
							<img
								ref={imgRef}
								alt='Crop me'
								src={cropImage.imageDataUrl}
								style={{
									transform: `scale(${scale}) rotate(${rotate}deg)`,
									// enforce height constraint only when height is more than 1.5 times greater than width to prevent image going outside the view
									height: imgDim.height / imgDim.width > 1.5 ? '60vh' : undefined,
								}}
								onLoad={onImageLoad}
							/>
						</ReactCrop>
					)}
				</div>
				{/* <div className="text-white">h-{previewCanvasRef.current?.height} w-{previewCanvasRef.current?.width}</div> */}
				<div className='hidden'>
					<canvas className='w-24' ref={previewCanvasRef} />
				</div>
				<div className='flex justify-between'>
					<Button
						variant='destructive'
						onClick={() =>
							Store.setState((draft) => {
								draft.imageCropView = {
									isDialogOpen: false,
								}
							})}
					>
						Cancel
					</Button>
					<div className='flex gap-4'>
						<Button onClick={onRotateClockwise}>
							<RotateCwIcon />
						</Button>
						<Button
							disabled={loading}
							variant='outline'
							className='text-primary'
							onClick={onCropDoneClick}
						>
							{loading ? <Spinner /> : 'Scan'}
						</Button>
					</div>
				</div>
			</DialogContent>
		</Dialog>
	)
}
