import { RefObject, useEffect, useState } from 'react'

const useIsBottomVisible = <T extends HTMLElement>(
	containerRef: RefObject<T>,
): boolean => {
	const [isBottomVisible, setIsBottomVisible] = useState(false)

	useEffect(() => {
		const container = containerRef.current
		if (!container) return

		const sentinel = document.createElement('div')
		sentinel.style.height = '1px' // Small height to ensure minimal space at the bottom
		container.appendChild(sentinel) // Append the sentinel to the container

		const observer = new IntersectionObserver(
			([entry]) => {
				setIsBottomVisible(entry.isIntersecting)
			},
			{
				root: container, // Use the container as the root
				rootMargin: '0px', // No margin
				threshold: 1.0, // Trigger when the sentinel is fully visible
			},
		)

		observer.observe(sentinel) // Observe the sentinel element

		return () => {
			observer.disconnect() // Cleanup observer on unmount
			container.removeChild(sentinel) // Remove the sentinel
		}
	}, [containerRef])

	return isBottomVisible
}

export default useIsBottomVisible
