import { useEffect } from 'react'
import { useOs } from '@mantine/hooks'

/**
 * Apply Styles to disable the Scroll Bar
 */
export default function DisableScrollbarStyles() {
	const os = useOs()
	const isMobile = os === 'android' || os === 'ios'

	useEffect(() => {
		if (isMobile) {
			// Create a new style element
			const style = document.createElement('style')
			style.innerHTML = `
                /* Hide scrollbar for Chrome, Safari and Opera */
                *::-webkit-scrollbar {
                display: none;
                }
            
                /* Hide scrollbar for IE, Edge and Firefox */
                * {
                -ms-overflow-style: none;  /* IE and Edge */
                scrollbar-width: none;  /* Firefox */
                }
            `

			// Append the style element to the head
			document.head.appendChild(style)

			// Cleanup the effect by removing the style when the component unmounts
			return () => {
				document.head.removeChild(style)
			}
		}
	}, [isMobile]) // Run the effect when isMobile changes

	return null
}
