import { useEffect, useRef, useState } from 'react'
import { Store } from '@/store'
import { useChatIdFromUrl } from '@/hooks/useChatIdFromUrl'
import { useRetrieveChat } from '@/mutations/useRetrieveChat'
import { useMetadataGeneration } from '@/mutations/useMetadataGeneration'
import { useSignedUrlMutation } from '@/mutations/useSignedUrl'
import { ExtendedChatMessage } from '@/store/types'
import { useGptStream } from '@/mutations/useGptStream'
import { useFileUpload } from '@/mutations/useFileUpload'

export function useChat() {
	const chats = Store.useBoundStore((state) => state.chats)
	const [inputMessage, setInputMessage] = useState('')
	const { chatId, generateChatId } = useChatIdFromUrl()
	const chat = chatId ? chats[chatId] : undefined

	const retrieveChat = useRetrieveChat()
	const metadataGeneration = useMetadataGeneration()
	const signedUrlMutation = useSignedUrlMutation()
	const gptStreamMutation = useGptStream()
	const fileUploadMutation = useFileUpload()
	const abortControllerRef = useRef<AbortController>()

	const lockChatSubmit = gptStreamMutation.isPending

	useEffect(() => {
		if ((chatId && !chat) || (chatId && chat && chat.messages.length === 0)) {
			retrieveChat.mutateAsync({ chatId })
		}
	}, [chatId])

	useEffect(() => {
		if (!lockChatSubmit && chat && chat.messages.length >= 2 && (!chat.chatTitle || !chat.category)) {
			metadataGeneration.mutate()
		}
	}, [chat?.chatId, chat?.messages.length, lockChatSubmit])

	const handleImageCropDone = async (file: File) => {
		const extension = file.name.split('.').pop()
		if (!extension) {
			return console.debug('Invalid extension')
		}
		const { uploadUrl, publicUrl } = await signedUrlMutation.mutateAsync({ extension })
		await fileUploadMutation.mutateAsync({ url: uploadUrl, file })
		const imageMessage = {
			role: 'user',
			type: 'image_url',
			content: publicUrl,
		} as const
		submitMessages([
			imageMessage,
			{
				role: 'user',
				type: 'text',
				content: 'Solve this for me',
			},
		])
	}

	const submitMessages = async (messages: ExtendedChatMessage[]) => {
		const assistantMessage = {
			role: 'assistant',
			type: 'text',
			content: '',
		} as const
		setInputMessage('')

		let localChatId = chatId ?? generateChatId()

		Store.appendMessagesToChat({
			chatId: localChatId,
			messages: messages.concat(assistantMessage),
		})
		const abortController = new AbortController()
		abortControllerRef.current = abortController
		await gptStreamMutation.mutateAsync({ chatId: localChatId, abortController })
	}

	const handleAbortStream = () => {
		abortControllerRef.current?.abort()
	}

	const handleSubmit = async (message: string) => {
		if (inputMessage.length === 0) {
			return
		}
		if (lockChatSubmit) {
			return
		}
		submitMessages([
			{
				role: 'user',
				type: 'text',
				content: message,
			},
		])
		if (chat && (!chat.category || !chat.chatTitle)) {
			metadataGeneration.mutate()
		}
	}

	return {
		lockChatSubmit,
		input: inputMessage,
		chatTitle: chat?.chatTitle,
		category: chat?.category,
		messages: chat ? chat?.messages : [],
		setInput: setInputMessage,
		handleImageCropDone,
		handleSubmit,
		handleAbortStream,
	}
}
