import { type QuizMessage } from '@/store/types'
import AssistantMessage from '@/components/AssistantMessage'
import { Store } from '@/store'
import { useChatIdFromUrl } from '@/hooks/useChatIdFromUrl'
import MarkdownRenderer from '@/components/MarkdownRenderer'
import { useSyncChat } from '@/mutations/useSyncChat'

export default function QuizMessage({ index, message }: { index: number; message: QuizMessage }) {
	const { chatId } = useChatIdFromUrl()
	const syncChat = useSyncChat()

	const handleAnswerSelect = (questionIdx: number, option: string) => {
		if (!chatId) return
		Store.setState(draft => {
			const quizMessage = draft.chats[chatId].messages[index] as QuizMessage
			quizMessage.content.questions[questionIdx].selectedOption = option
		})
		syncChat.mutate()
	}

	return (
		<AssistantMessage>
			<div className='flex flex-col gap-4'>
				<h2 className='mb-2 text-xl'>{message.content.quizTitle}</h2>
				<p className='mb-4'>{message.content.quizDescription}</p>
				{message.content.questions.map((question, questionIdx) => (
					<div key={questionIdx} className='flex flex-col gap-2'>
						<h3 className='text-lg'>
							<MarkdownRenderer content={question.question} />
						</h3>
						<div className='grid grid-cols-1 sm:grid-cols-2 gap-2'>
							{question.options.map((option, optionIdx) => {
								const isQuestionAnswered = question.selectedOption
								const isSelected = question.selectedOption === option
								const isCorrect = option === question.correctAnswer
								// bg color by default
								let bgColor = 'bg-zinc-700'
								if (isQuestionAnswered && isCorrect) {
									bgColor = 'bg-green-500'
								}
								if (isQuestionAnswered && isSelected && !isCorrect) {
									bgColor = 'bg-red-500'
								}
								return (
									<div
										key={optionIdx}
										className={`p-2 rounded-lg cursor-pointer ${bgColor}`}
										onClick={() =>
											!question.selectedOption && handleAnswerSelect(questionIdx, option)}
									>
										<div className='text-white px-2'>
											<MarkdownRenderer content={option} skipEquationFormatting={true} />
										</div>
									</div>
								)
							})}
						</div>
						{question.selectedOption && (
							<div className='mt-2 text-sm'>
								{question.selectedOption === question.correctAnswer
									? <p className='text-green-400'>Correct! Well done.</p>
									: (
										<p className='text-yellow-500'>
											Incorrect. The correct answer is {question.correctAnswer}
										</p>
									)}
							</div>
						)}
					</div>
				))}
			</div>
		</AssistantMessage>
	)
}
