import { useAuthFromStorage } from '@/hooks/useAuthStorage'
import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import ChatView from '@/components/ChatView'
import GlobalAlertDialog from '@/components/GlobalAlertDialog'
import Sidebar from '@/components/Sidebar'
import GuestAuthManager from '@/components/GuestAuthManager'
import { Toaster } from '@/components/ui/sonner'
import PrivacyDialog from '@/components/PrivacyDialog'

export default function HomePage() {
	const [params, setParams] = useSearchParams()
	const { setAuthToken } = useAuthFromStorage()
	const token = params.get('token')

	useEffect(() => {
		if (token) {
			setParams(prev => {
				// token is a sensitive information, so we remove it from the URL
				prev.delete('token')
				return prev
			})
			setAuthToken(token)
		}
	}, [])

	return (
		<div>
			<GuestAuthManager />
			<GlobalAlertDialog />
			<ChatView />
			<Sidebar />
			<Toaster />
			<PrivacyDialog />
		</div>
	)
}
