import { useListVideos } from '@/hooks/useListVideos'
import { useRemoveVideo } from '@/mutations/useRemoveVideo'
import { useNavigate } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'
import { ArrowLeft, ChevronRight, Dot, EllipsisVerticalIcon, MoreVertical, Square } from 'lucide-react'
import { Store } from '@/store'
import { Key, useState } from 'react'
import { SavedVideo } from '@/store/types'
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuLabel,
	DropdownMenuSeparator,
	DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'

function formatDate(dateString: string) {
	const date = new Date(dateString)
	const today = new Date()
	const yesterday = new Date(today)
	yesterday.setDate(today.getDate() - 1)

	if (
		date.getDate() === today.getDate() &&
		date.getMonth() === today.getMonth() &&
		date.getFullYear() === today.getFullYear()
	) {
		return 'today'
	} else if (
		date.getDate() === yesterday.getDate() &&
		date.getMonth() === yesterday.getMonth() &&
		date.getFullYear() === yesterday.getFullYear()
	) {
		return 'yesterday'
	} else {
		return date.toLocaleDateString()
	}
}

export default function SavedVideosPage() {
	const { data: savedVideos } = useListVideos()
	const removeFromSavedVideos = useRemoveVideo()
	const navigate = useNavigate()
	const queryClient = useQueryClient()
	const [searchTerm, setSearchTerm] = useState('')

	const handleVideoClick = (video: SavedVideo) => {
		const currentVideo = {
			title: video.title,
			duration: video.duration,
			videoLink: video.videoLink,
			thumbnailLink: video.thumbnail,
		}

		Store.setState(draft => {
			draft.videoRecommendations = {
				currentVideo: currentVideo,
				currentMessage: {
					role: 'assistant',
					type: 'video_recommendations',
					content: {
						short: video.recommendations.short,
						medium: video.recommendations.medium,
						long: video.recommendations.long,
					},
					metadata: { query: '' },
				},
			}
		})

		navigate('/video')
	}

	const handleGoToChat = (e: any, chatId: string, videoLink: string) => {
		e.stopPropagation()
		Store.setState(draft => {
			draft.referenceVideoLink = videoLink
			draft.chatId = chatId
		})
		navigate('/')
	}

	const handleUnsaveVideo = (e: any, video: SavedVideo) => {
		e.stopPropagation()
		removeFromSavedVideos.mutate(video.title, {
			onSuccess: () => {
				queryClient.invalidateQueries({ queryKey: ['list-saved-videos'] })
			},
		})
	}

	const filteredVideos = savedVideos
		?.filter((video: SavedVideo) =>
			video.title.toLowerCase().startsWith(searchTerm.toLowerCase()) ||
			(video.category && video.category.toLowerCase().startsWith(searchTerm.toLowerCase()))
		)
		.sort((a: SavedVideo, b: SavedVideo) => {
			const aTitleMatch = a.title.toLowerCase().startsWith(searchTerm.toLowerCase())
			const bTitleMatch = b.title.toLowerCase().startsWith(searchTerm.toLowerCase())
			const aCategoryMatch = a.category?.toLowerCase().startsWith(searchTerm.toLowerCase())
			const bCategoryMatch = b.category?.toLowerCase().startsWith(searchTerm.toLowerCase())

			// sort by title match first, then by category match
			if (aTitleMatch && !bTitleMatch) return -1
			if (!aTitleMatch && bTitleMatch) return 1
			if (aCategoryMatch && !bCategoryMatch) return -1
			if (!aCategoryMatch && bCategoryMatch) return 1
			return 0
		})

	return (
		<div className='flex flex-col h-screen bg-black'>
			<div className='flex items-center px-4 h-14'>
				<button onClick={() => navigate(-1)} className='p-2 -ml-2'>
					<ArrowLeft className='w-6 h-6 text-zinc-400' />
				</button>
				<h1 className='flex-1 text-center text-white text-base font-medium'>Saved Videos</h1>
				<div className='w-10' />
			</div>

			<hr className='h-0.5 mb-4 mt-4 bg-[#1F1F1F] border-black' />

			<div className='px-4 py-3 mb-6'>
				<div className='relative'>
					<input
						type='text'
						placeholder='e.g. mathematics, chemistry'
						className='w-full h-12 px-4 bg-zinc-900 rounded-xl text-white placeholder:text-zinc-500 focus:outline-none focus:ring-1 focus:ring-zinc-700'
						value={searchTerm}
						onChange={(e) => setSearchTerm(e.target.value)}
					/>
				</div>
			</div>

			{(!savedVideos || savedVideos.length === 0)
				? (
					<div className='flex flex-col items-center justify-center flex-1 p-4 text-center'>
						<p className='text-lg font-medium text-white mb-2'>No saved videos yet</p>
						<p className='text-sm text-zinc-400'>Videos you save will appear here</p>
					</div>
				)
				: (
					<div className='flex-1 px-4 space-y-6 pb-4 overflow-y-auto'>
						{filteredVideos?.map((video: SavedVideo) => (
							<div
								key={video.videoLink}
								className='relative bg-zinc-900 rounded-xl overflow-hidden'
								onClick={() => handleVideoClick(video)}
							>
								<div className='flex p-4 gap-4'>
									<div className='relative flex-shrink-0'>
										<img
											src={video.thumbnail}
											alt=''
											className='w-[150px] h-[84px] rounded-lg object-cover'
										/>
										{video.duration && (
											<div className='absolute bottom-1 right-1 px-1 py-0.5 text-[10px] font-medium text-white bg-black/60 rounded'>
												{video.duration}
											</div>
										)}
									</div>

									<div className='flex-1 min-w-0'>
										<h3 className='text-sm font-medium text-white line-clamp-2 mb-1'>
											{video.title}
										</h3>
										<p className='text-xs text-zinc-400 mb-2'>{video.category}</p>
										<div className='flex items-center justify-between'>
											<div className='flex items-center gap-1.5'>
												<div className='w-1.5 h-1.5 rounded-full bg-gradient-to-r from-[#1CF0FA] to-[#ADFF69]' />
												<span className='text-xs text-zinc-400'>
													Saved {formatDate(video.savedAt)}
												</span>
											</div>
											<button
												onClick={(e) => handleGoToChat(e, video.chatId, video.videoLink)}
												className='text-xs font-medium'
											>
												<img src='/go-to-chat.svg' />
											</button>
										</div>
									</div>

									<DropdownMenu>
										<DropdownMenuTrigger className='absolute top-2 right-2'>
											<MoreVertical className='w-5 h-5 text-zinc-400' />
										</DropdownMenuTrigger>
										<DropdownMenuContent align='end' className='w-48 bg-zinc-800 border-zinc-700'>
											<DropdownMenuItem
												onClick={(e) => handleUnsaveVideo(e, video)}
												className='text-white focus:text-white focus:bg-zinc-700'
											>
												<img src='/unsave.svg' />
											</DropdownMenuItem>
										</DropdownMenuContent>
									</DropdownMenu>
								</div>
							</div>
						))}
					</div>
				)}
		</div>
	)
}
