import { useMutation } from '@tanstack/react-query'
import axios from 'axios'
import { SERVER_URL } from '@/env-vars'
import { Store } from '@/store'
import { useAuthFromStorage } from '@/hooks/useAuthStorage'
import { useSyncChat } from './useSyncChat'
import { useChatIdFromUrl } from '@/hooks/useChatIdFromUrl'

export function useMetadataGeneration() {
	const { chatId } = useChatIdFromUrl()
	const { authToken } = useAuthFromStorage()
	const syncChat = useSyncChat()

	return useMutation({
		retry: 3,
		retryDelay: (attempt) => Math.exp(attempt),
		mutationKey: ['metadata-generation', chatId],
		mutationFn: async () => {
			if (!chatId) throw Error('ChatId Not Found')
			const messages = Store.useBoundStore.getState().chats[chatId].messages.slice(-5)
			if (messages.length < 2) {
				throw Error('Insufficient Message Length')
			}
			const response = await axios.post(
				`${SERVER_URL}/chat/metadata`,
				{ messages },
				{
					headers: { Authorization: `Bearer ${authToken}` },
				},
			)
			const data = response.data as { chatTitle: string; category: string }
			return { ...data, chatId }
		},
		onSuccess: (data) => {
			if (!data) return
			Store.setState((draft) => {
				draft.chats[data.chatId].chatTitle = data.chatTitle
				draft.chats[data.chatId].category = data.category
			})
			syncChat.mutate()
		},
	})
}
