import axios from 'axios'
import { useMutation } from '@tanstack/react-query'
import { useGuestID } from '@/hooks/useGuestID'
import { useAuthFromStorage } from '@/hooks/useAuthStorage'
import { useCallback, useEffect } from 'react'
import { jwtDecode } from 'jwt-decode'

/**
 * Whenever GuestID changes and no auth token exists,
 * it creates a jwt token from backend using GuestID as a unique source.
 */
export default function GuestAuthManager() {
	const { guestID } = useGuestID()

	const { authToken, setAuthToken } = useAuthFromStorage()

	const guestAuth = useMutation({
		mutationKey: ['guestAuth'],
		mutationFn: async ({ guestID }: { guestID: string }) => {
			const { data } = await axios.post('/api/auth/guest', { guestID })
			return data.token as string
		},
		onSuccess: setAuthToken,
	})

	const checkAuthTokenForExpiry = useCallback(async () => {
		// If either authToken or guestID is not present, exit early
		if (!authToken || !guestID) return

		try {
			// Decode the JWT token to get the expiry time (in seconds since Unix epoch)
			const tokenExpiryTime = jwtDecode(authToken).exp

			// Get the current time in seconds
			const currentTimeInSeconds = Date.now() / 1000

			// Calculate the time 2 minutes from now
			const bufferTimeInSeconds = 2 * 60 // 2 minutes in seconds
			const upcomingExpiryTime = currentTimeInSeconds + bufferTimeInSeconds

			// Check if the token is already expired or will expire within the next 2 minutes
			if (tokenExpiryTime && tokenExpiryTime < upcomingExpiryTime) {
				console.debug('Auth token is about to expire or has already expired. Triggering reset.')
				guestAuth.mutate({ guestID })
			}
		} catch (error) {
			console.error('Error decoding the auth token:', error)
		}
	}, [authToken, guestID])

	// refresh token on expire
	useEffect(() => {
		checkAuthTokenForExpiry()
	}, [guestID, authToken])

	useEffect(() => {
		if (!authToken && guestID) {
			guestAuth.mutate({ guestID })
		}
	}, [guestID])

	return null
}
