import {
	AlertDialog,
	AlertDialogAction,
	AlertDialogContent,
	AlertDialogDescription,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogTitle,
} from '@/components/ui/alert-dialog'
import { useLocalStorage } from '@mantine/hooks'
import { useNavigate } from 'react-router-dom'

export default function PrivacyDialog() {
	const [privacyRead, setPrivacyRead] = useLocalStorage({
		key: 'privacy-read',
		defaultValue: false,
		getInitialValueInEffect: false,
	})
	const navigate = useNavigate()

	return (
		<AlertDialog open={!privacyRead}>
			<AlertDialogContent className='bg-primary border-secondary w-5/6'>
				<AlertDialogHeader>
					<AlertDialogTitle className='text-primary-foreground text-3xl mb-8'>
						TutorCat - Privacy
					</AlertDialogTitle>
					<AlertDialogDescription className='text-primary-foreground space-y-4 text-left text-base sm:text-lg'>
						<p>
							This app provides access to educational videos relevant to your chosen topics, utilizing
							YouTube’s Data API. By using this app, you understand and accept that certain features rely
							on data and content from YouTube, which are subject to{' '}
							<a className='underline text-blue-700 font-bold' href='https://www.youtube.com/t/terms'>
								YouTube’s Terms of Service
							</a>{' '}
							and{' '}
							<a className='underline text-blue-700 font-bold' href='https://policies.google.com/privacy'>
								Google’s Privacy Policy
							</a>.
						</p>
						<p>
							In accordance with these terms, the app may retrieve and display specific information made
							available through YouTube. For further details on how we handle and protect your data,
							please refer to our{' '}
							<a className='underline text-blue-700 font-bold' href='/privacy-policy'>Privacy Policy</a>.
						</p>
					</AlertDialogDescription>
				</AlertDialogHeader>
				<AlertDialogFooter className='justify-start sm:justify-start'>
					<AlertDialogAction
						className='bg-red-700 hover:bg-red-700 my-2 px-8 font-bold'
						onClick={() => setPrivacyRead(true)}
					>
						Accept
					</AlertDialogAction>
					<AlertDialogAction
						className='bg-red-700 hover:bg-red-700 my-2 px-8 font-bold'
						onClick={() => navigate('/privacy-policy')}
					>
						Settings
					</AlertDialogAction>
				</AlertDialogFooter>
			</AlertDialogContent>
		</AlertDialog>
	)
}
