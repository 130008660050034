import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { SERVER_URL } from '@/env-vars'
import { useAuthFromStorage } from '@/hooks/useAuthStorage'
import { SavedVideo } from '@/store/types'

// Define the hook for listing saved videos
export function useListVideos() {
	const { authToken } = useAuthFromStorage()

	return useQuery({
		queryKey: ['list-saved-videos'],
		queryFn: async () => {
			const response = await axios.get(`${SERVER_URL}/favorites/list`, {
				headers: { Authorization: `Bearer ${authToken}` },
			})
			return response.data.favorites as SavedVideo[]
		},
	})
}
