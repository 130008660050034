import { useState } from 'react'
import MarkdownRenderer from '@/components/MarkdownRenderer'

const str =
	"Sure, let's break down the Schrödinger equation in both its time-dependent and time-independent forms.\\n\\n $x^2$"

export default function MarkdownPlayground() {
	const [content, setContent] = useState<string>(str)
	const [escape, setEscape] = useState(false)

	const unescapeString = (escapedStr: string) => {
		return escapedStr
			.replace(/\\n/g, '\n')
			.replace(/\\\\/g, '\\')
	}

	const transformedContent = escape ? unescapeString(content) : content

	return (
		<div className='flex flex-col'>
			<label className='p-4' htmlFor='content'>Enter Markdown Content</label>
			<textarea
				id='content'
				className='p-4 border border-black'
				onChange={(e) => setContent(e.target.value)}
				value={content}
			>
			</textarea>
			<div className='bg-gray-400'>
				<label className='p-4' htmlFor='escape'>Escape String</label>
				<input
					type='checkbox'
					checked={escape}
					onChange={() => setEscape(e => !e)}
				/>
			</div>
			<MarkdownRenderer content={transformedContent} className='p-4 text-white bg-zinc-700' />
		</div>
	)
}
