import { type FollowUpsMessage } from '@/store/types'
import UserMessage from '@/components/UserMessage'
import { CircleHelpIcon } from 'lucide-react'

export default function FollowupMessage(
	{ message, handleSelect }: { message: FollowUpsMessage; handleSelect: (message: string) => void },
) {
	return (
		<UserMessage>
			<div className='flex flex-col gap-2 max-w-[32rem]'>
				{message.content.followUps.map((followup, idx) => (
					<div
						key={idx}
						className='bg-neutral-800 px-4 py-2 rounded-xl cursor-pointer'
						onClick={() => handleSelect(followup)}
					>
						{followup}
					</div>
				))}
				<div className='text-sm flex align-middle'>
					<CircleHelpIcon size={18} className='mr-1' />
					<em>Click on an option to ask the question</em>
				</div>
			</div>
		</UserMessage>
	)
}
