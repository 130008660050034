import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider, Routes } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import DisableScrollbarStyles from '@/components/DisableScrollbarStyles'
import HomePage from '@/pages/HomePage'
import TestPage from '@/pages/TestPage'
import SavedVideosPage from '@/pages/SavedVideosPage'
import MarkdownDocumentPage from '@/components/MarkdownDoc'
import VideoPage from '@/pages/VideoPage'

const router = createBrowserRouter(
	createRoutesFromElements([
		<Route path='/' element={<HomePage />} />,
		<Route path='/video' element={<VideoPage />} />,
		<Route path='/saved' element={<SavedVideosPage />} />,
		<Route path='/test' element={<TestPage />} />,
		<Route path='/privacy-policy' element={<MarkdownDocumentPage path='/docs/privacy-policy.md' />} />,
		<Route path='/terms-of-service' element={<MarkdownDocumentPage path='/docs/terms-of-service.md' />} />,
	]),
)

const queryClient = new QueryClient()

export default function App() {
	return (
		<QueryClientProvider client={queryClient}>
			<RouterProvider router={router} />
			<DisableScrollbarStyles />
		</QueryClientProvider>
	)
}
