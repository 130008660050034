import React from 'react'
import YouTubeVideoScreen from '@/components/YoutubeVideoScreen'

export default function VideoPage() {
	return (
		<div className='bg-zinc-900'>
			<YouTubeVideoScreen />
		</div>
	)
}
