import { MutableRefObject, useEffect, useRef } from 'react'

interface UseAutoScrollProps {
	streaming: boolean
	targetRef: MutableRefObject<HTMLElement | null>
	threshold?: number // Optional scroll threshold, defaults to 250px
}

const useAutoScroll = ({ streaming, targetRef, threshold = 250 }: UseAutoScrollProps) => {
	// Track the interval for auto-scroll
	const scrollIntervalRef = useRef<number | null>(null)

	const scrollToBottom = () => {
		if (targetRef.current) {
			targetRef.current.scroll({
				behavior: 'smooth',
				top: targetRef.current.scrollHeight,
			})
		}
	}

	useEffect(() => {
		// Clear any previous intervals
		if (scrollIntervalRef.current) {
			clearInterval(scrollIntervalRef.current)
		}

		// Scroll to the bottom once when streaming starts
		if (streaming) {
			scrollToBottom()

			scrollIntervalRef.current = setInterval(() => {
				const target = targetRef.current
				if (target) {
					// Calculate how many pixels are remaining to scroll
					const distanceToBottom = target.scrollHeight - target.scrollTop - target.clientHeight

					// Only scroll if within the threshold
					if (distanceToBottom <= threshold) {
						scrollToBottom()
					}
				}
			}, 100)
		}

		// Cleanup interval on component unmount or streaming stop
		return () => {
			if (scrollIntervalRef.current) {
				clearInterval(scrollIntervalRef.current)
			}
		}
	}, [streaming, targetRef, threshold])

	return { scrollToBottom }
}

export default useAutoScroll
