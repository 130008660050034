import { useLocalStorageState } from 'ahooks'
import { useEffect } from 'react'
import { ulid } from 'ulidx'

export const useGuestID = () => {
	const [guestID, setGuestID] = useLocalStorageState<string>('guestID', { listenStorageChange: true })

	const resetGuestID = () => setGuestID(ulid())

	useEffect(() => {
		// guestID will be available in the window object (inject by iOS/Android)
		if (window.guestID) {
			setGuestID(window.guestID)
			// Handle the case where the app is opened directly in a browser
		} else if (!guestID) {
			setGuestID(ulid())
		}
	}, [])

	return { guestID, resetGuestID }
}
