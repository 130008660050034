import React from 'react'
import VideoRecommendationCard from '@/components/VideoRecommendationCard'
import { YoutubeVideoDetails } from '@/store/types'

type VideoRecommendationSectionProps = {
	title: string
	videos: YoutubeVideoDetails[]
}

const VideoRecommendationSection: React.FC<VideoRecommendationSectionProps> = ({ title, videos }) => (
	<div className='mb-6'>
		<h2 className='text-lg font-semibold mb-2 text-white'>{title}</h2>
		<div className='flex pb-4 w-full overflow-x-auto overflow-y-hidden whitespace-nowrap gap-2'>
			{videos.map((video) => <VideoRecommendationCard key={video.title} {...video} />)}
		</div>
	</div>
)

export default VideoRecommendationSection
