import { Store } from '@/store'
import { ulid } from 'ulidx'

export const useChatIdFromUrl = () => {
	const chatId = Store.useBoundStore((state) => state.chatId)

	const setChatId = (newChatId?: string) => {
		Store.setState((draft) => {
			draft.chatId = newChatId
		})
	}

	const generateChatId = () => {
		const newChatId = ulid()
		setChatId(newChatId)
		return newChatId
	}

	return { chatId, setChatId, generateChatId }
}
