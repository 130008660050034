import { useLocalStorageState } from 'ahooks'
import { jwtDecode } from 'jwt-decode'

const AUTH_TOKEN_KEY = 'token'
7

type JWTPayload = {
	id: string
	email: string
	username: string
	profilePicture: string
	provider: string
}

export const useAuthFromStorage = () => {
	const [authToken, setAuthToken] = useLocalStorageState<string>(AUTH_TOKEN_KEY, { listenStorageChange: true })

	const decodedToken = authToken ? jwtDecode<JWTPayload>(authToken) : null

	return { authToken, setAuthToken, decodedToken } as const
}
