import { useMutation } from '@tanstack/react-query'
import axios from 'axios'
import { SERVER_URL } from '@/env-vars'
import { Store } from '@/store'
import { Chat } from '@/store/types'
import { useAuthFromStorage } from '@/hooks/useAuthStorage'
import { useChatIdFromUrl } from '@/hooks/useChatIdFromUrl'

export function useSyncChat() {
	const { authToken } = useAuthFromStorage()
	const { chatId } = useChatIdFromUrl()

	return useMutation({
		mutationKey: ['sync-chat', chatId],
		mutationFn: async () => {
			if (!chatId) throw Error('ChatId Not Found')
			const chat = Store.useBoundStore.getState().chats[chatId]
			const response = await axios.patch(
				`${SERVER_URL}/chat/sync`,
				{
					chatId,
					chatTitle: chat?.chatTitle,
					category: chat?.category,
					messages: chat?.messages,
				},
				{
					headers: { Authorization: `Bearer ${authToken}` },
				},
			)

			if (response.status < 400) {
				return response.data as Chat
			} else if (response.status >= 500) {
				throw new Error('Server Error')
			} else {
				console.debug(response.status)
			}
		},
	})
}
