import { forwardRef, useEffect, useState } from 'react'
import tm from 'markdown-it-texmath'
import markdownIt from 'markdown-it'
import katex from 'katex'

export interface MarkdownRendererProps extends React.ButtonHTMLAttributes<HTMLDivElement> {
	content: string
	skipEquationFormatting?: boolean
}

const MarkdownRenderer = forwardRef<HTMLDivElement, MarkdownRendererProps>(
	({ content, className, skipEquationFormatting = false }, ref) => {
		const [mounted, setMounted] = useState(false)

		useEffect(() => {
			setMounted(true)
			return () => setMounted(false)
		}, [])

		if (!mounted) return <div ref={ref} className={className} />

		const md = new markdownIt({ html: true })
			.use(
				tm,
				{
					engine: katex,
					delimiters: 'dollars',
					katexOptions: { macros: {} },
				},
			)

		const transformedContent = content
			// Replace all \[ and \] with $$ for block math
			.replaceAll(/\\\[\s*/g, '\n$$').replaceAll(/\s*\\\]/g, '$$')
			// Replace all \( and \) with $ for inline math
			.replaceAll(/\\\(\s*/g, '$').replaceAll(/\s*\\\)/g, '$')
			.replaceAll(/\\\$/g, '＄') // hack to prevent katex inabilty to parse $ sign

		const innerHTML = md.render(transformedContent)

		const parser = new DOMParser()
		const doc = parser.parseFromString(innerHTML, 'text/html')

		// Remove all <span class="katex-html" aria-hidden="true"> elements
		const unwantedSpans = doc.querySelectorAll('span.katex-html[aria-hidden="true"]')
		unwantedSpans.forEach(span => span.remove())

		// Style Table Header Cells
		const thElements = doc.querySelectorAll('th')
		thElements.forEach(th => th.classList.add('p-3', 'text-left', 'bg-zinc-700'))

		// Style Table Cells
		const tdElements = doc.querySelectorAll('td')
		tdElements.forEach(td => td.classList.add('p-3'))

		// Style Table Rows
		const trElements = doc.querySelectorAll('tr')
		trElements.forEach(tr => tr.classList.add('border-zinc-700'))

		// Style Table Head Section
		const theadElements = doc.querySelectorAll('thead')
		theadElements.forEach(thead => thead.classList.add('border-none'))

		// Style The Whole Table
		const tableElements = doc.querySelectorAll('table')
		tableElements.forEach(table => table.classList.add('bg-zinc-800', 'rounded-lg', 'overflow-hidden'))

		// Enclose each table in a div and style it to allow horizontal overflow
		tableElements.forEach(table => {
			const parentDiv = doc.createElement('div')
			parentDiv.classList.add('w-full', 'overflow-x-auto')
			table.parentNode?.insertBefore(parentDiv, table)
			parentDiv.appendChild(table)
		})

		if (!skipEquationFormatting) {
			const eqElements = doc.querySelectorAll('eq')
			const eqNElements = doc.querySelectorAll('eqn')
			const equationElements = [...eqElements, ...eqNElements]

			// style Equation Blocks for better contrast and readability + allows horizontal scroll for long equations
			equationElements.forEach(equation => {
				if (equation.parentElement?.childNodes.length === 1) {
					equation.parentElement?.classList.add(
						'w-full',
						'overflow-x-auto',
						'bg-neutral-700',
						'rounded-sm',
						'p-3',
						'my-2',
					)
				}
			})
		}

		const finalHTML = doc.body.innerHTML

		// Refer to globals.css for the prose class. It is used to style the markdown content.
		return (
			<div ref={ref} className={className + ' prose text-sm'} dangerouslySetInnerHTML={{ __html: finalHTML }} />
		)
	},
)

MarkdownRenderer.displayName = 'MarkdownRenderer'

export default MarkdownRenderer
