import { useMutation } from '@tanstack/react-query'
import axios from 'axios'
import { SERVER_URL } from '@/env-vars'
import { useAuthFromStorage } from '@/hooks/useAuthStorage'
import { toast } from 'sonner'
import { SaveVideoType } from '@/store/types'

export function useSaveVideo() {
	const { authToken } = useAuthFromStorage()

	return useMutation({
		mutationKey: ['add-to-saved-videos'],
		mutationFn: async (videoData: SaveVideoType) => {
			const response = await axios.post(
				`${SERVER_URL}/favorites/add`,
				videoData,
				{
					headers: { Authorization: `Bearer ${authToken}` },
				},
			)
			return response.data
		},
		onError: () => {
			toast.error('Failed to save video. Please try again.')
		},
	})
}
